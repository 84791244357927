import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import lumiance from 'lumiance'

// TODO: Use aliases for these paths?
import tokens from 'vibemap-constants/design-system/build/json/variables.json'

const Chip = ({
  text,
  backgroundColor,
  size,
  ...props
}) => {

  // Automatic font color based on backgrond
  const [backgroundLum, setBackgroundLum] = React.useState(0);
  const [color, setColor] = React.useState(tokens.color.base.white)
  const [fontSize, setFontSize] = React.useState(`${tokens.font.size.base}px`)

  useEffect(() => {
    const backgroundLum = lumiance(backgroundColor)
    setBackgroundLum(backgroundLum)

    setColor(backgroundLum > 0.5
      ? tokens.color.base.black
      : tokens.color.base.white)

    setFontSize((size == 'small')
    ? `${tokens.font.size.tiny}px`
    : `1.4rem`)

  }, [backgroundColor])

  const borderColor = tokens.color.base.gray['600']

  return (
    <div
      className='vibe'
      color={color}
      style={{
        backgroundColor: backgroundColor,
        border: `solid 1px ${borderColor}`,
        borderRadius: `999rem`,
        color: color,
        display: `inline-block`,
        fontFamily: `${tokens.font.family.sans}`,
        fontSize: fontSize,
        fontWeight: `${tokens.font.weight.normal}`,
        padding: `0.4rem 1.4rem`,
        whiteSpace: `nowrap`
      }}>
      {text}
    </div>
  );
}

Chip.propTypes = {
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  text: PropTypes.string
}

Chip.defaultProps = {
  backgroundColor: '#FFFFFF',
  size: 'normal'
}

export const ChipProps = Chip.propTypes
export default Chip;