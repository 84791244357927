import React from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'

import SEO from "components/seo"
import Header from "components/header"
import ImpactArea from "components/sections/impactArea"
import NeighborhoodPosts from "components/postIndex/neighborhoods"
import Footer from "components/footer"

const NeighborhoodIndex = props => {
  const { data } = props
  const allPosts = data.allWpNeighborhood.edges

  let seo = data.allWpPage.edges[0].node.seo
  seo.title = `${seo.title} | Vibemap`

  return (
    <div>
      <main>
        <TemplateContextProvider posts={allPosts} >
          <PlacesContextProvider>
          <SEO
            lang="en-US"
            title={seo.title}
            data={seo} />
          <Header />
          <ImpactArea data={data.allWpPage.edges[0].node} />
          <NeighborhoodPosts posts={allPosts} />
          </PlacesContextProvider>
        </TemplateContextProvider>
      </main>
    </div>
  )
}

export default NeighborhoodIndex

export const neighborhoodQuery = graphql`
  query WPNeighborhoodsQuery {
    allWpPage(filter: { slug: { eq: "neighborhoods" } }) {
      edges {
        node {
          id
          databaseId
          title
          date
          title
          author {
            node {
              name
            }
          }
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNoindex
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          impactArea {
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
                publicURL
              }
              mediaItemUrl
            }
            heading
            vibeset {
              ... on WpVibeset {
                id
                slug
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpNeighborhood {
      edges {
        node {
          id
          title
          databaseId
          uri
          featuredImage {
            node {
              caption
              mediaItemUrl
              slug
              title
            }
          }
          categories {
            nodes {
              slug
            }
          }
          postDetails {
            city {
              ... on WpCity {
                id
                slug
                title
              }
            }
            vibeset {
              ... on WpVibeset {
                id
                slug
                title
                vibes {
                  nodes {
                    slug
                  }
                }
              }
            }
          }
          neighborhood {
            photos {
              localFile {
                publicURL
              }
            }
            map {
              city
              latitude
              longitude
            }
          }
          content
        }
      }
    }
  }`
