import React from 'react';

import Icon from 'react-icons-kit';
import { heart } from 'react-icons-kit/fa/heart';
import { heartO } from 'react-icons-kit/fa/heartO';

import * as style_variables from 'vibemap-constants/design-system/build/json/variables.json'
const tokens = style_variables['default']

// TODO: Defined token
const iconSize= '1.0em'

export function Saved({ isSaved, handleClick, place }) {
  return (
    <div
      className='save-place'
      style={{
        cursor: `point`,
        float: `right`
      }}
      onClick={e => handleClick(e, place)}
      title='Click to save to your list' >
      {isSaved
        ? <Icon
            icon={heart}
            title='Click to save to your list'
            size={iconSize}/>
        : <Icon
            icon={heartO}
            color='#AAAAAA'
            title='Click to remove from your list'
            size={iconSize}/>}
    </div>
  );
}
